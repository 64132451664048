'use strict';

Gri.module({
  name: 'parallax-alpha',
  ieVersion: null,
  $el: $('.parallax-alpha'),
  container: '.parallax-alpha',
  fn: function () {

    // region variables
    const $this = this.$el;
    // endregion

    // region run
    $this.parallax();
    // endregion

    // region events
    $(window).on('load', function () {
      $this.find('li').each(function () {
        let posX = Math.floor(Math.random(0, 10) * 100);
        let posY = Math.floor(Math.random(0, 10) * 100);
        $(this).css({
          top: posY + '%',
          left: posX + '%'
        });
      });
    });
    // endregion

    // region events functions

    // endregion

    // region functions

    // endregion

  }
});
